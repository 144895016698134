<template>
  <el-dialog
    :visible.sync="isShow"
    title="提示"
    width="600px"
    class="login-dialog"
    :show-close="false"
    @close="close"
    destroy-on-close
  >
    <div slot="title" class="dialog-title">
      <div class="left">Login</div>
      <div class="right">
        <div class="tag">New Account</div>
      </div>
    </div>
    <el-form ref="formData" :model="formData" status-icon>
      <div class="label">YOUR EMAIL</div>
      <el-form-item prop="email" :rules="rules.email">
        <el-input
          v-model="formData.email"
          autocomplete="off"
          placeholder="email@example.com"
        ></el-input>
      </el-form-item>
      <div class="label">PASSWORD</div>
      <el-form-item prop="password" :rules="rules.password">
        <el-input
          v-model="formData.password"
          show-password
          autocomplete="off"
          placeholder="Password"
          type="password"
        ></el-input>
      </el-form-item>
    </el-form>
    <div class="footer">
      <div class="tips">
        <div class="left-tip">
          <el-checkbox v-model="rememberCustomer" class="font-light"
            >Remember me</el-checkbox
          >
        </div>
        <div class="right-tip">
          <el-button size="small" type="text" @click="resetPasswordHandler"
            >Reset Password?</el-button
          >
        </div>
      </div>
      <div class="button">
        <el-button
          class="sign-button"
          style="width: 100%"
          type="primary"
          @click="submitForm('formData')"
          >Sign In
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { signIn } from "@/api";
export default {
  props: {
    visible: Boolean,
  },
  data() {
    const validateEmail = (rule, value, callback) => {
      const reg =
        /^[A-Za-z0-9]+([-._][A-Za-z0-9]+)*@[A-Za-z0-9]+(-[A-Za-z0-9]+)*(\.[A-Za-z]{2,6}|[A-Za-z]{2,4}\.[A-Za-z]{2,3})$/;
      if (!value) {
        callback(new Error("Please enter your email address"));
      }
      if (!reg.test(value)) {
        callback(new Error("Please enter a valid email address"));
      }
    };
    return {
      formData: {
        email: "",
        password: "",
      },
      rememberCustomer: false,
      rules: {
        email: [{ validator: validateEmail, trigger: "blur" }],
        password: [
          {
            required: true,
            message: "Please enter your password",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    async submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        const params = {
          account: this.formData.account,
          password: this.formData.password,
          code: this.formData.code,
        };
        if (valid) {
          const {
            data: { code, msg },
          } = await signIn(params);
          if (code === 200) {
            this.$message({
              message: msg,
              type: "success",
            });
            this.resetForm("formData");
            this.$router.replace({
              name: "home",
            });
          } else {
            this.$message({
              message: msg,
              type: "error",
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetPasswordHandler() {
      this.$router.push({ path: "/forgot" });
    },
    close() {
      this.$emit("update:visible", false);
    },
  },
  computed: {
    isShow() {
      return this.$props.visible;
    },
  },
};
</script>

<style lang="scss" scoped>
.login-dialog {
  .dialog-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #ddd;
    padding-bottom: 20px;
    font-size: 18px;
    align-items: center;
    font-weight: bold;
    .right {
      padding: 10px 20px;
      background-color: #e8e0d2;
      border-radius: 5px;
      font-size: 16px;
      color: #e9b973;
    }
  }
  .label {
    font-weight: 600;
    font-size: 14px;
    padding: 20px 0;
  }
  .footer {
    margin-top: 20px;

    .tips {
      margin-top: 30px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-size: 12px;
      color: #ccc;
    }
  }
}
</style>
