<template>
  <div class="login">
    <div class="login-bg"></div>
    <div class="signin">
      <div class="title">Sign in</div>
      <el-tabs v-model="activeName" @tab-click="resetForm(activeName)">
        <el-tab-pane label="Student" name="student">
          <el-form ref="student" :model="formData" status-icon>
            <el-form-item prop="email" :rules="rules.email">
              <el-input
                v-model="formData.email"
                autocomplete="off"
                placeholder="Account"
              ></el-input>
            </el-form-item>
            <el-form-item prop="code" :rules="rules.code">
              <el-input
                v-model="formData.code"
                placeholder="Code"
                @input="formData.code = String(formData.code).slice(0, 6)"
              >
                <el-button
                  slot="suffix"
                  type="text"
                  @click="sendVerifyHandler"
                  :disabled="isSend"
                  >{{ sendVerifyText }}</el-button
                >
              </el-input>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="Administrator" name="administrator">
          <el-form ref="administrator" :model="formData" status-icon>
            <el-form-item prop="email">
              <el-input
                v-model="formData.email"
                autocomplete="off"
                placeholder="Account"
              ></el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input
                v-model="formData.password"
                show-password
                autocomplete="off"
                placeholder="Password"
                type="password"
              ></el-input>
            </el-form-item>
            <!-- <el-form-item prop="code" :rules="rules.code">
              <el-input
                v-model="formData.code"
                placeholder="Code"
                @input="formData.code = String(formData.code).slice(0, 6)"
              >
                <el-button
                  slot="suffix"
                  type="text"
                  @click="sendVerifyHandler"
                  :disabled="isSend"
                  >{{ sendVerifyText }}</el-button
                >
              </el-input>
            </el-form-item> -->
          </el-form>
        </el-tab-pane>
      </el-tabs>
      <div class="footer">
        <div class="button">
          <el-button
            class="sign-button"
            style="width: 100%"
            type="primary"
            @click="submitForm(activeName)"
            >Sign in
          </el-button>
        </div>
        <div class="tips">
          <div class="left-tip">
            No account?
            <el-button size="small" type="text" @click="registerHandler"
              >Register Now</el-button
            >
          </div>
          <!-- <div class="right-tip">
            <el-button size="small" type="text" @click="resetPasswordHandler"
              >Forget Password</el-button
            >
          </div> -->
        </div>
      </div>
    </div>
    <!-- <el-button
      @click="showDialog1 = true"
      style="position: absolute; top: 10px; left: 10px"
      >open1</el-button
    >
    <el-button
      @click="showDialog2 = true"
      style="position: absolute; top: 10px; left: 120px"
      >open2</el-button
    > -->
    <loginDialog1 :visible.sync="showDialog1" />
    <loginDialog2 :visible.sync="showDialog2" />
  </div>
</template>

<script>
import { login, createLoginOPT } from "../api/index";
import loginDialog1 from "@/components/loginDialog1.vue";
import loginDialog2 from "@/components/loginDialog2.vue";
import { setUserId, setUserName, setUserType } from "@/utils/store";
import { mapActions } from "vuex";
export default {
  name: "login",
  components: {
    loginDialog1,
    loginDialog2,
  },
  data() {
    return {
      activeName: "student",
      formData: {
        email: "",
        password: "",
        code: "",
      },
      rules: {
        email: [
          {
            required: true,
            message: "Please enter your name",
            trigger: "blur",
          },
          {
            type: "email",
            message: "Please input correct email",
            trigger: ["blur", "change"],
          },
        ],
        password: [
          {
            required: true,
            message: "Please enter your password",
            trigger: "blur",
          },
        ],
        code: [
          {
            required: true,
            message: "Please enter the verification code",
            trigger: "blur",
          },
          {
            max: 6,
            message: "Please enter a valid verification code",
            trigger: "change",
          },
        ],
      },
      sendVerifyText: "Send verification code",
      isSend: false,
      showDialog1: false,
      showDialog2: false,
    };
  },
  methods: {
    ...mapActions(["updateUserInfo"]),
    loginHandler() {
      const login_type =
        this.activeName === "administrator" ? "loginByAdmin" : "loginV1";
      const { email, password, code } = this.formData;
      const param =
        this.activeName === "administrator"
          ? {
              user_email: email,
              user_pwd: password,
            }
          : { user_email: email, verify_code: code };
      login(login_type, param).then((res) => {
        const { code, data = {}, msg } = res.data || {};
        const {
          _id,
          student_givenName_pinyin,
          student_lastName_pinyin,
          account_name,
          user_id,
        } = data || {};
        if (code == 0) {
          const _userinfo = {
            type: this.activeName,
            name: `${student_givenName_pinyin} ${student_lastName_pinyin}`,
            school: account_name,
            subscribeStatus: "",
          };
          this.updateUserInfo(_userinfo);
          setUserId(this.activeName === "administrator" ? user_id : _id.$id);
          setUserName(_userinfo.name);
          setUserType(this.activeName);
          this.$UserData.user_name = _userinfo.name;
          this.$message.success("Login success");
          this.$router.replace(
            this.activeName === "administrator" ? "/submission" : "/home"
          );
        } else if (code == -3) {
          this.$message.success(msg);
        } else {
          this.$message.error(msg);
        }
      });
    },
    async submitForm(formName) {
      this.$refs[formName].validate((val) => {
        if (val) {
          this.loginHandler();
        }
      });
    },
    resetForm(formName) {
      this.activeName = formName;
      this.$refs[formName].resetFields();
    },
    resetPasswordHandler() {
      this.$router.push({ path: "/forgot" });
    },
    registerHandler() {
      this.$router.replace({
        path: "/register",
      });
    },
    async sendVerifyHandler() {
      if (!this.formData.email) {
        this.$message.error("Please enter your name");
        return;
      }
      const res = await createLoginOPT(this.formData.email);
      const { code, msg } = res.data || {};
      if (code === 0) {
        this.$message.success(msg);
        let num = 5;
        this.sendVerifyText = `Send it again ${num--} seconds later`;
        this.isSend = true;
        let timeId = setInterval(() => {
          this.sendVerifyText = `Send it again ${num--} seconds later`;
          if (num < 0) {
            this.sendVerifyText = "Send verification code";
            this.isSend = false;
            clearInterval(timeId);
            timeId = null;
          }
        }, 1000);
      } else {
        this.$message.error(msg);
      }

      // let validateFieldList = [];
      // this.$refs[this.activeName].validateField(
      //   ["email", "password"],
      //   (val) => {
      //     if (!val) {
      //       validateFieldList.push(val);
      //       if (
      //         validateFieldList.length == 2 &&
      //         validateFieldList.every((item) => item === "")
      //       ) {
      //         let num = 5;
      //         this.sendVerifyText = `Send it again ${num--} seconds later`;
      //         this.isSend = true;
      //         let timeId = setInterval(() => {
      //           this.sendVerifyText = `Send it again ${num--} seconds later`;
      //           if (num < 0) {
      //             this.sendVerifyText = "Send verification code";
      //             this.isSend = false;
      //             clearInterval(timeId);
      //             timeId = null;
      //           }
      //         }, 1000);
      //         this.loginHandler();
      //       }
      //     }
      //   }
      // );
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  // --color-primary: #409eff;
  // --color-primary--lighter: #3f89e0;
  // --color-primary--lightest: #b1d9fa;

  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: right;
  .login-bg {
    flex: 1;
    background-image: url(../assets/images/login-bg.jpg);
    background-color: #16015b;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
  .signin {
    width: 40%;
    height: 100%;
    background-color: #fff;
    padding: 0 100px;

    .title {
      margin-top: 50%;
      margin-bottom: 25px;
      font-weight: bolder;
      font-size: 25px;
    }

    .footer {
      margin-top: 20px;

      .tips {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 12px;
        color: #ccc;
      }
    }
  }

  //复写颜色样式
  /deep/.el-tabs__active-bar {
    background-color: var(--c-primary);
  }
  /deep/.el-tabs__item {
    color: #000;
    &:hover {
      color: var(--c-primary);
    }
  }
  /deep/.el-button.el-button--primary {
    background-color: var(--c-primary);
    border-color: var(--c-primary);
  }

  /deep/.el-button--text {
    color: var(--c-primary);
    &.is-disabled {
      color: #ccc;
    }
  }

  ///deep/.el-form-item.is-error .el-input__validateIcon {
  //  color: var(--color-primary);
  //}

  /deep/.el-form-item.is-error,
  /deep/.el-input__inner:focus,
  /deep/.el-form-item.is-error,
  /deep/.el-textarea__inner:focus {
    border-color: var(--c-primary);
  }

  /deep/ .el-tabs__nav-wrap::after {
    background-color: transparent;
  }

  /deep/ .el-input-group__append {
    background-color: #fff;
    color: var(--c-primary);
  }
}
</style>
